import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { RootState } from 'app/store';
import { Partner } from 'app/interfaces/partner';
import Skeleton from 'app/components/Skeleton/Skeleton';
import Table from 'app/components/Table/Table';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { ReactComponent as IconEdit } from '../../../../static/icons/edit.svg';
import { ReactComponent as IconClose } from '../../../../static/icons/cross-small.svg';
import {
    getPartners,
    addPartner,
    clearPartnerError,
    editPartnerEmail,
    editPartnerName,
    sendPartnersData,
    setPartnerError,
} from '../../../../state/campaign/campaignSlice';
import { setPartnerTableSort } from '../../../../state/ui/campaignDetails/campaignDetailsPageSlice';
import { getPartnersTableDataSorted } from '../../../CampaignDetails/campaignDetailsSelectors';
import { CampaignPartnersTableEntry } from '../../../CampaignDetails/interfaces/campaignPartners';
import styles from './CampaignPartners.module.scss';
import { partnerTableHeaders } from 'pages/CampaignDetails/constants/campaignPartnersTableConstants';

interface Error {
    message?: string;
    field?: string;
}


export default function CampaignPartners(): React.ReactElement {
    const dispatch = useDispatch();

    const partners = useSelector<RootState, CampaignPartnersTableEntry[]>(getPartnersTableDataSorted);
    const [addNewActionActive, setAddNewActionActive] = useState(false);
    const partnerErrors = useSelector<RootState, any[]>((state) => state.campaign.campaignPartners.partnerErrors);
    const partnerServerError = useSelector<RootState, any>((state) => state.campaign.campaignPartners.partnerServerError);
    const isPartnersLoading = useSelector<RootState, boolean>((state) => state.campaign.isPartnersLoading);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
    const campaignId = useSelector<RootState, any>((state) => (state.campaign.campaign || {}).campaign_id);
    const partnerTableSort = useSelector<RootState, any>((state) => state.campaignDetailsPage.partnerTableSort);

    const isLoading = isPartnersLoading || isCampaignLoading;

    const [enabledEditMode, setEnabledEditMode] = useState(-1);
    const [warning, setWarning] = useState<string>('');
    const [currentPartnerId, setCurrentPartnerId] = useState<string | undefined>('');

    const findNewPartner = partners?.find((item: any) => !!item.isNew);
    const findCurrentPartner = partners?.find((item: any) => item.partner_id === currentPartnerId);
    const findSamePartnerName = findNewPartner && enabledEditMode === 0
        ? partners?.find((item: any) => (!item.isNew && item.name === findNewPartner?.name))
        : partners?.find((item: any) => (item.partner_id !== currentPartnerId && item.name === findCurrentPartner?.name))

    useEffect(() => {
        if (findSamePartnerName) {
            setWarning('Partner with this name already exists')
        } else {
            setWarning('')
        }
    }, [findSamePartnerName])

    const submitPartnersData = (partner: Partner) => {
        let err: Error | null = null;
        if (!partner.support_email) {
            err = { message: 'Email cannot be empty', field: 'support_email' };
        }
        if (!partner.name) {
            err = { message: 'Name cannot be empty', field: 'name' };
        }
        if (err) {
            dispatch(setPartnerError({ ...partnerErrors, [partner.partner_id]: err }));
            return;
        } else {
            dispatch(clearPartnerError(partner.partner_id));
            setEnabledEditMode(-1);
        }
        dispatch(sendPartnersData(partner));
        setAddNewActionActive(false)
    };

    const handleNameChange = (value: string, partner_id: string) => {
        dispatch(clearPartnerError({ id: partner_id, field: 'name' }));
        dispatch(editPartnerName({ partner_id, name: value }));
        setCurrentPartnerId(partner_id);
    };

    const handleEmailChange = (value: string, partner_id: string) => {
        dispatch(clearPartnerError({ id: partner_id, field: 'support_email' }));
        dispatch(editPartnerEmail({ partner_id, support_email: value }));
    };

    const handleEditableRow = (rowIndex: number, partner_id?: string) => {
        setCurrentPartnerId(partner_id);
        setEnabledEditMode(rowIndex);
    }

    const tableData = !partners
        ? []
        : partners.map(({ partner_id, name, support_email, isNew }, index) => [
            <div className={styles.actionCell}>
                {enabledEditMode === index ?
                    (<IconClose onClick={() => {
                        handleEditableRow(-1);
                        setAddNewActionActive(false);
                        dispatch(getPartners(campaignId));
                    }} className={styles.iconEdit} />)
                    :
                    (<IconEdit onClick={() => {
                        handleEditableRow(index, partner_id)
                        setAddNewActionActive(true);
                    }} className={styles.iconEdit} />)
                }
            </div>,
            <div className={styles.inputBlock}>
                <Input
                    disableErrorLabel={true}
                    error={
                        partnerErrors[partner_id] && partnerErrors[partner_id].field === 'name'
                            ? partnerErrors[partner_id].message
                            : null
                    }
                    type="text"
                    value={name}
                    disabled={enabledEditMode !== index}
                    onChange={(e) => handleNameChange(e.target.value, partner_id)}
                />
                {warning && enabledEditMode === index &&
                    <span className={styles.warning}>{warning}</span>
                }
            </div>,
            <div className={classNames(styles.inputBlock, {
                [styles.inputWarningBlock]: !!warning && enabledEditMode === index,
            })}>
                <Input
                    disableErrorLabel={true}
                    error={
                        partnerErrors[partner_id] && partnerErrors[partner_id].field === 'support_email'
                            ? partnerErrors[partner_id].message
                            : null
                    }
                    type="email"
                    autoComplete="off"
                    value={support_email || ''}
                    disabled={enabledEditMode !== index}
                    onChange={(e) => handleEmailChange(e.target.value, partner_id)}
                />
            </div>,
            <div className={classNames({
                [styles.submitWarning]: !!warning && enabledEditMode === index,
            })}>
                <Button
                    type="action"
                    className={styles.submitButton}
                    disabled={enabledEditMode !== index}
                    onClick={() => submitPartnersData({ support_email, partner_id, name, isNew })}
                >
                    {isNew ? 'Add new' : 'Update'}
                </Button>
            </div>
        ]
        );

    const handleScroll = () => {
        const element = document.getElementById('partners_table');
        if (element) {
            element.scrollIntoView();
        }
    };

    return (
        <Skeleton isLoading={isLoading}>
            {!isLoading && (
                <div className={styles.root}>
                    <div className={styles.partnersList} id="partners_table">
                        <Table
                            id={`${campaignId}-partners`}
                            rows={tableData}
                            headers={partnerTableHeaders}
                            sort={partnerTableSort}
                            onSort={(field: any) => {
                                setEnabledEditMode(-1);
                                setAddNewActionActive(false)
                                dispatch(setPartnerTableSort(field))
                                dispatch(getPartners(campaignId));
                            }}
                        />
                        <div className={styles.roundButtonHolder}>
                            <button
                                disabled={addNewActionActive}
                                className={styles.roundButton}
                                onClick={() => {
                                    handleScroll();
                                    handleEditableRow(0)
                                    dispatch(addPartner());
                                    setAddNewActionActive(true);
                                }}>
                                <Tooltip position="left" tooltip="Add Campaign Partner">
                                    <span>+</span>
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                    {partnerServerError && <p className={styles.formError}>{partnerServerError}</p>}
                </div>
            )}
        </Skeleton>
    );
}
