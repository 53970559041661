import React from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';

interface Props {
    error?: string | boolean | null;
    type?: string;
    name?: string;
    placeholder?: string;
    value: string | number | undefined;
    className?: string;
    autoComplete?: string;
    min?: number;
    max?: number;
    multiline?: boolean;
    required?: boolean;
    disabled?: boolean;
    disableErrorLabel?: boolean;
    toggle?: boolean;
    checked?: boolean;
    forwardedRef?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
}

function Input({
    name,
    autoComplete = 'on',
    type = 'text',
    placeholder = '',
    value,
    onChange,
    onBlur,
    className,
    min,
    max,
    forwardedRef,
    disableErrorLabel = false,
    required = false,
    disabled = false,
    multiline = false,
    toggle = false,
    checked,
    error,
}: Props): React.ReactElement {
    return (
        <div className={classNames({[styles.root]: !toggle})}>
            {multiline ? (
                <textarea
                    required={required}
                    disabled={disabled}
                    name={name}
                    ref={forwardedRef as React.ForwardedRef<HTMLTextAreaElement>}
                    className={classNames(styles.input, className, { [styles.hasError]: !!error })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    dir="auto"
                />
            ) : toggle ? (
                <input
                    type="checkbox"
                    disabled={disabled}
                    className={classNames({
                        [styles.fakeSwitcher]: true,
                        [styles.fakeSwitcherEnabled]: !!value,
                        [styles.disabled]: disabled
                    })}
                    value={value}
                    checked={checked}
                    name={name}
                    onChange={onChange}
                />
            ) : (
                <input
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    name={name}
                    ref={forwardedRef as React.ForwardedRef<HTMLInputElement>}
                    className={classNames(styles.input, className, { [styles.hasError]: !!error })}
                    type={type}
                    min={min}
                    max={max}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    dir="auto"
                />
            )}

            {!!error && !disableErrorLabel && <p className={styles.errorMessage}>{error}</p>}
        </div>
    );
}

export default React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>) => {
    return <Input {...props} forwardedRef={ref} />;
});
